.user-image-container {
  position: relative;
  border-radius: 50%;
  border: 0px solid #000;
  background-color: #000;
  display: inline-block;
  vertical-align: -0.21875rem;
  height: 1em;
  width: 1em;
  line-height: inherit;
  font-size: inherit;
  color: #fff;
  overflow: hidden;
  box-sizing: content-box;
}

.user-image-container img,
.user-image-container .letter {
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  height: 1em;
  width: 1em;
  line-height: 1em;
  font-size: 1em;
}
.user-image-container .letter {
  text-align: center;
  font-size: 0.6em;
  margin-top: 50%;
  margin-left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.user-image-container.color-1 {
  border-color: var(--course-num-1-ribbon-color);
  background-color: var(--course-num-1-ribbon-color);
}
.user-image-container.color-2 {
  border-color: var(--course-num-2-ribbon-color);
  background-color: var(--course-num-2-ribbon-color);
}
.user-image-container.color-3 {
  border-color: var(--course-num-3-ribbon-color);
  background-color: var(--course-num-3-ribbon-color);
}
.user-image-container.color-4 {
  border-color: var(--course-num-4-ribbon-color);
  background-color: var(--course-num-4-ribbon-color);
}
.user-image-container.color-5 {
  border-color: var(--course-num-5-ribbon-color);
  background-color: var(--course-num-5-ribbon-color);
  color: #000;
}
.user-image-container.color-6 {
  border-color: var(--course-num-6-ribbon-color);
  background-color: var(--course-num-6-ribbon-color);
  color: #000;
}
.user-image-container.color-7 {
  border-color: var(--course-num-7-ribbon-color);
  background-color: var(--course-num-7-ribbon-color);
}
.user-image-container.color-8 {
  border-color: var(--course-num-8-ribbon-color);
  background-color: var(--course-num-8-ribbon-color);
}
